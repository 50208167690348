import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import { cardHistory } from '../Request/Histories';
import ReactCardFlip from 'react-card-flip';
import { accountBalance } from '../Request/Wallets';

const BalanceCard = () => {
    const [ip, setIP] = useState("");

    const getData = async () => {
        fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => setIP(data.ip))
        .catch(error => console.log(error))
      };

    const [isFlipped, setFlipped] = useState(false);
    let user = localStorage.getItem('delox-user');
    if (user != null) {
        user = JSON.parse(user);
    }
    const handleClick = (e) => {
        e.preventDefault();
        setFlipped(!isFlipped);
      }
      const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);

      const [accBalance, setaccountBalance] = useState(accountBalance());
      const newaccountBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = parseFloat(data.account_balance);
        }
        return balance;
    }

    const getDate = (date) => new Date(date).toDateString();

    useEffect(()=>{
    

        if(window && window.init_template){
            window.init_template();
            var creditCard = document.querySelector('.credit-card');
        if(creditCard){
            creditCard.addEventListener( 'click', function() {
                creditCard.classList.toggle('is-flipped');
            });
        }
        } 

        window.addEventListener('updatedStorage', function (e) {
            setaccountBalance(newaccountBalance());
            
      }, false);
getData();
        
    }, [])
  return (
    <div id="page-content">
        <div className="row text-center" style={{marginTop: "-20px"}}>
        <div className='col-12 col-md-6'>
            <div className="mt-2 content mx-0">
                <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
                    <div onClick={handleClick} className="color-white fw-bolder">
                        <div className="card text-bg-dark" style={{borderRadius: "15px", height: '225px'}}>
                            <img style={{borderRadius: "15px", height: '100%'}} src="https://res.cloudinary.com/dg8pxsdme/image/upload/v1700641102/lincfooter_zihikw.png" className="card-img" alt="..." />
                            <div className="card-img-overlay">
                                <div className='position-absolute w-100 top-0 font-14 d-flex justify-content-between' style={{transform: "translate(0px, 20px)"}}>
                                <div className='font-18'>ACCOUNT BALANCE.</div>
                                <div style={{transform: "translate(-65px, 0px)"}} className='text-end'>
                                    <img className='img-fluid' style={{width: '80px'}} src="https://res.cloudinary.com/dd6ueb6xn/image/upload/w_100,h_41,c_fit/beta-trade-with_x9ejor.png"/>
                                </div>
                                </div>
                                
                                <div className="card-text position-absolute fs-3 text-center d-flex justify-content-center" style={{bottom: '0', transform: "translate(0px, -100px)"}}>
                                    <span className="mx-2">{getAmount(accBalance)}</span> 
                                </div>
                                <div className='position-absolute w-100 bottom-0 font-14 d-flex justify-content-between fw-lighter' style={{transform: "translate(15px, -30px)"}}>
                                <div style={{transform: "translate(0px, 20px)"}}>
                                    <span className='font-11  opacity-50'>Trader Name </span><br/><span className='font-20'>{user.name}</span>
                                </div>
                                <div className='text-end' style={{transform: "translate(-65px, 20px)"}}>
                                    <span className='font-11 opacity-50'>Current IP</span><br/><span className='font-20'>{ip}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div onClick={handleClick} className="color-white fw-bolder">
                        <div className="card text-bg-dark" style={{borderRadius: "15px",  height: '225px'}}>
                        <img style={{borderRadius: "15px",  height: '100%'}} src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1676284772/unitcard3.png" className="card-img" alt="..." />
                        <div className="card-img-overlay" style={{borderRadius: "15px"}}>
                            <div className="card-title text-end mt-4"><span className="mx-2">{getAmount(accBalance)}</span></div>
                            <div className="card-text text-end">Account Balance</div>
                        </div>
                        </div>
                    </div>
                    </ReactCardFlip>
            </div>
        </div>
        </div>
    </div>
  )
}

export default BalanceCard