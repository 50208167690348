import React, {useState, useEffect}  from 'react';
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import { useNavigate } from "react-router-dom";
import {
  triggerSuccess,
  triggerError,
  setErrorMessage,
  setSuccessMessage,
} from '../Request/StatusFormatter';

const UpdatePassword = () => {
  let navigate = useNavigate();
    const initialLogin = {
        code: '',
        password: '',
        password_confirmation: ''
    }
    const [error, setErros] = useState('');
    const [loginForm, setLoginForm] = useState(initialLogin);
    const onChange = (e) =>
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });

    const { isLoading: isSendingRequest, mutate: postLogin } = useMutation(
        async () => {
          return await apiClient.post(`/api/update-password`, {
            code: loginForm.code,
            password: loginForm.password,
            password_confirmation: loginForm.password_confirmation
          });
        },
        {
          onSuccess: (res) => {
            if (res.data.message === 'unverified') {
              navigate('/verify-user');
            } else {              
            setSuccessMessage(res.data);
            triggerSuccess();
            setTimeout(() => {
                window.location.href = '/login';
            }, 3000);
            
            }
            
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);  
            triggerError();
            let myerror = err.response?.data || err; 
            if (myerror.errors) {
                setErros(myerror.errors);
            }  
          },
        }
      );
      const onSubmit = async() =>{
        postLogin();
        // await apiClient.get("/sanctum/csrf-cookie").then(() => {
          
        // })
      }   

      useEffect(() => {
        document.title = 'LOGIN';
      });
  return (
    <div className="page-content my-0 py-0">
        <div className="card card-fixed">
            <div className="card-center mx-3 px-4 py-4 bg-transparent rounded-m">
                <div className="text-center d-flex justify-content-center mb-4">
                  <img src="https://res.cloudinary.com/dd6ueb6xn/image/upload/v1719693147/beta-trade-with_x9ejor.png" height="60" alt="logo"/>
                </div>
                <h1 className="font-30 font-800 mb-0 text-center text-white">Update Password</h1>
                <p className='text-center' style={{color: '#eff1f0'}}>Fill the form below to recover your account.</p>
                <div className="card card-style bg-fade-yellow border border-fade-yellow alert alert-dismissible show fade p-0 mb-3">
                    <div className="content my-3">
                        <div className="d-flex">
                            <div className="align-self-center no-click">
                                <i className="bi bi-info-square font-33 color-yellow-dark d-block"></i>
                            </div>
                            <div className="align-self-center no-click">
                                <p className="text-white mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                Check your email for authorization code
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <span className='text-danger'>{error !== '' && error.code}</span>
                <div className="form-custom form-label form-border form-icon mb-3" style={{border: '1px solid #ffffff', borderRadius: '8px'}}>
                    <i className="text-white bi bi-person-circle font-13"></i>
                    <input style={{color: '#f9fbfa'}} autoFocus='off' autoComplete="off" type="text" name="code" onChange={onChange} className="form-control login-forms rounded-xs" id="code" placeholder="enter authorization code"/>
                    <label htmlFor="code" className="text-white">Authorization Code</label>
                    <span style={{color: '#eff1f0'}}>(required)</span>
                </div>
                <span className='text-danger'>{error !== '' && error.password}</span>
                <div className="form-custom form-label form-border form-icon mb-4"  style={{border: '1px solid #ffffff', borderRadius: '8px'}}>
                    <i className="text-white bi bi-asterisk font-13"></i>
                    <input autoFocus='off' autoComplete="off" style={{color: '#f9fbfa'}} type="password" onChange={onChange} name="password" className="form-control text-white rounded-xs" id="password" placeholder="Enter new password"/>
                    <label htmlFor="password" className="text-white">New Password</label>
                    <span style={{color: '#eff1f0'}}>(required)</span>
                </div>
                <div className="form-custom form-label form-border form-icon mb-4"  style={{border: '1px solid #ffffff', borderRadius: '8px'}}>
                    <i className="text-white bi bi-asterisk font-13"></i>
                    <input autoFocus='off' autoComplete="off" style={{color: '#f9fbfa'}} type="password" onChange={onChange} name="password_confirmation" className="form-control text-white rounded-xs" id="password_confirmation" placeholder="Confirm password"/>
                    <label htmlFor="password_confirmation" className="text-white">Confirm Password</label>
                    <span style={{color: '#eff1f0'}}>(required)</span>
                </div>
                
                
                <div onClick={onSubmit} className="btn btn-full gradient-green shadow-bg shadow-bg-s mt-4">
                  {isSendingRequest? <span>updating...</span>: <span>Update Password</span>}
                </div>
                <div className="row">
                    <div className="col-6 text-start">
                        <Link to="/forgot-password" className="font-14 color-green-light font-weight-bold pt-4 d-block">Forgot Password?</Link>
                    </div>
                    <div className="col-6 text-end">
                        <Link to="/register" className="font-14 color-green-light pt-4 d-block">Create Account</Link>
                    </div>
                </div>
            </div>
            <div style={{backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1659204120/ser-bg.png)"}} className="card-overlay rounded-0 m-0 opacity-90 gradient-green">
            </div>
        </div>

    </div>
  )
}

export default UpdatePassword